import "@mantine/core/styles.css";
import React from "react";
import { MantineProvider } from "@mantine/core";
import { theme } from "./src/theme";
import { Layout } from "./src/components/Layout/Layout";

export function wrapPageElement({ element, props }) {
  return (
    <MantineProvider theme={theme}>
      <Layout {...props}>
        {element}
      </Layout>
    </MantineProvider>
  )
}
