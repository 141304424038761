import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';

//brand
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons/faSquareFacebook';
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons/faSquareXTwitter';
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons/faSquareInstagram';
import { faSquareYoutube } from '@fortawesome/free-brands-svg-icons/faSquareYoutube';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';

//duotone
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { faCalendarDays } from '@fortawesome/pro-duotone-svg-icons/faCalendarDays';

import 'typeface-poppins';
import 'typeface-open-sans';
import 'typeface-roboto';

library.add(
  faSquareFacebook,
	faSquareXTwitter,
	faSquareInstagram,
	faSquareYoutube,
	faLinkedin,
  faClock,
  faCalendarDays,
);

export const Layout = ({ children }) => {

	return (
		<>
			{children}
		</>
	);
};