import { createTheme } from '@mantine/core';

export const theme = createTheme({
  fontFamily: ['Poppins', 'sans-serif'].join(),
  colors: {
    workwaveBlue: [
      '#002D5C',
    ],
    darkGray: [
      '#5b646f',
    ],
    mediumGray: [
      '#5f6972',
    ]
  },
});
